@import "~@/styles/variables";














































































































































































































































































































.autocomplete {
  position: relative;

  .inputs {
    ::v-deep {
      input {
        position: relative;
        border-radius: 0;

        &:focus {
          z-index: 1;
        }

        &:first-child {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }

        &:last-child {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
    }
  }
  ::v-deep .list-group-item {
    &:first-child,
    &:last-child {
      &:before {
        display: none;
      }
    }
  }
}
