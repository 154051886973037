@import "~@/styles/variables";
































.autocomplete-option {
  flex-shrink: 0;
  &::before {
    display: none;
  }
}
