@import "~@/styles/variables";

















































































.autocomplete-list {
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  margin-top: -1px;
  max-height: 400px;
  overflow: auto;
}
